<template>
  <div id="step2">
    <i class="el-icon-success icon"></i>
    <div class="step2-title">提交成功！</div>
    <ul class="step-list">
      <li>
        <span class="red">{{ state.sucCount }}&nbsp;</span
        >家企业匹配成功
      </li>
      <li>
        <span class="red">{{ state.failCount }}&nbsp;</span
        >家企业未匹配成功
        <a
          v-if="state.failCount!=0"
          :href="staticURL + state.failFile"
          class="active-color step2-download-file"
          >点击下载</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { URL } from "@/util/request";
export default {
  props: {
    state: {
      type: Object,
    },
  },
  data() {
    return {
      staticURL: URL.static,
    };
  },
};
</script>

<style lang="scss" scoped>
#step2 {
  text-align: center;
  .icon {
    font-size: 60px;
    margin-top: 48px;
    color: #8ec31f;
  }
  .step2-title {
    font-size: 24px;
    color: #374567;
    margin: 24px 0 13px;
    font-weight: 500;
    text-align: center;
  }
  .step-list > li {
    font-size: 14px;
    color: #374567;
    margin-bottom: 11px;
    // margin-left: 80px;

    .red {
      color: #fc555e;
    }
  }
  .step2-download-file {
    margin-left: 10px;
  }
  ul,
  li {
    list-style: disc;
    text-align: left;
  }
}
</style>