<template>
  <div id="step3">
    <i class="el-icon-success icon"></i>
    <div class="step2-title">匹配入库成功</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#step3 {
    text-align: center;
  .icon{
    font-size: 60px;
    margin-top: 48px;
    color: #8EC31F;
  }
  .step2-title {
    font-size: 24px;
    color: #374567;
    margin: 24px 0 13px;
    font-weight: 500;
  }
}
</style>