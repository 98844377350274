<template>
  <div>
    <el-steps space='80%' :active="step" align-center>
      <el-step
        v-for="(item,index) in stepTitle"
        :key="index"
        :title="item"
      >
        <template slot="icon">
            <span class="border" :class="step>index?'active-border':''"></span>
        </template>
      </el-step>      
    </el-steps>
  </div>
</template>

<script>
export default {
    props:{
        stepTitle:{
            type:Array
        },
        step:{
            type:Number
        }
    },
    data(){
        return{
            active:1
        }
    }
};
</script>

<style lang="scss" scoped>
$default-color:#00A0E9;
    .border{
        width: 8px ;
        height: 8px;
        background: #7D869C;
        border-radius: 8px;
        border: 2px solid #fff;
    }
    .active-border{
        background: $default-color;
    }
    ::v-deep .el-step__icon.is-text{
        border: 0;
        width: 15px;
        // height: 10px;
    }
    ::v-deep .el-step__title{
        font-weight: 400;
        font-size: 14px;
    }
</style>