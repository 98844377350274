<template>
  <el-dialog title="批量上传" :visible.sync="dialogVisible">
    <el-step :stepTitle="title" :step="step"></el-step>
    <div class="content">
      <resource-step1
        @handleSuccess="handleSuccess"
        :url="url"
        v-if="step == 1"
        :downloadUrl="downloadUrl"
        :step="step"
      ></resource-step1>
      <resource-step2 :state="data" v-else-if="step == 2"></resource-step2>
      <resource-step3 v-else></resource-step3>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button
        :class="isClick ? '' : 'unClick'"
        type="primary"
        @click="next"
        >{{ step == 1 || step == 2 ? "下一步" : "确认" }}</el-button
      >
      <el-button @click="dialogVisible = false">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Step from "@/components/steps";
import { Step1, Step2, Step3 } from "./index";
import { crmGetTemplate } from "@/api/crm/resource_library";
export default {
  props: {
    url: {
      type: String,
    },
    tab: {
      type: Number,
    },
  },
  components: {
    "el-step": Step,
    "resource-step1": Step1,
    "resource-step2": Step2,
    "resource-step3": Step3,
  },

  data() {
    return {
      dialogVisible: false,
      step: 1,
      isClick: false,
      data: {}, //请求返回参数
      title: ["批量上传文件", "匹配结果", "保存入库"],
      downloadUrl: "",
    };
  },
  watch: {
    $route(e) {
      if (this.step != 1) {
        this.step = 1;
      }
    },
    tab:{
      immediate:true,
      handler(val){
        this.getStaticTemplate(val)
      }
    }
  },
  methods: {
    showDialogVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    handleSuccess(response) {
      if (response.status == 200) {
        this.isClick = true;
        this.data = response.data;
      }
    },
    next() {
      if (this.isClick) {
        if (this.step < 3) {
          this.step++;
        } else {
          this.dialogVisible = false;
          this.$emit("finishAdd");
          this.step = 1;
        }
      }
    },
    getStaticTemplate(tab) {
      crmGetTemplate(tab).then((res) => {
        if (res.status == 200) {
          this.downloadUrl = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: 30px 0 50px;
  display: flex;
  justify-content: center;
}
.unClick {
  opacity: 0.6;
}
</style>