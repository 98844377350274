<template>
  <el-menu
    class="el-menu-vertical-demo"
    @select="selectEvent"
    :default-active="defaultActive+''"
  >
    <el-menu-item
      v-for="(item, index) in menus"
      :key="item.title"
      :index="index+''"
    >
      <img :src="index==defaultActive?require('@/assets/images/'+item.ActiveDefaultIcon+''):require('@/assets/images/'+item.defaultIcon+'') " alt="">
      <span slot="title">{{ item.title }}</span>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  props: {
    menus: {
      type: Array,
    },
    defaultActive: {
      type: Number,
    },
  },
  methods:{
    selectEvent(index){
      this.$emit('changeTab',index);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-menu-item{
  img{
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}
</style>