<template>
  <div class="resource-box">
    <el-container>
      <el-aside width="153px">
        <el-menu
          class="menu-box"
          :menus="menus"
          :defaultActive="tab"
          @changeTab="changeTab"
        ></el-menu>
      </el-aside>
      <el-main class="main-box">
        <el-row>
          <!-- <el-button>添加企业</el-button> -->
          <el-button @click="addGroup">批量上传</el-button>
        </el-row>
        <div class="table-box">
          <industry-el-table
            :tableData="tableData"
            :tableTh="menus[tab].tableTh"
            :isClick="menus[tab].isClick"
            @cell-click='detailEvent'
          >
            <el-table-column label="事项" key="matter">
              <template slot-scope="scope">
                <div
                  class="active-color"
                  v-if="scope.row.itemId"
                  @click="showMatter(scope.$index, scope.row)"
                >
                  查看事项
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column label="项目">
              <template>
                <div>-</div>
              </template>
            </el-table-column>
            <el-table-column label="落地项目">
              <template>
                <div>-</div>
              </template>
            </el-table-column>
          </industry-el-table>
        </div>
        <el-row
          v-if="tableData.length > 0"
          type="flex"
          align="middle"
          justify="end"
        >
          <el-pagination
            v-if="total > 10"
            :total="total"
            @changePage="changePage"
            :currentPage="page"
          ></el-pagination>
        </el-row>
        <resource-add
          :url="url"
          ref="showDiogle"
          :tab="this.tab - '' + 1"
          @finishAdd="finishAdd"
        ></resource-add>
      </el-main>
    </el-container>
    <matter ref="matter"></matter>
  </div>
</template>
<script>
import Menu from "@/components/menus";
import Table from "@/components/tables";
import Pagination from "@/components/paginations";
import Add from "./add/add";
import { URL } from "@/util/request";
import { crmResList, crmListPageCount } from "@/api/crm/resource_library";
import Matter from "./matter/index";
export default {
  components: {
    "el-menu": Menu,
    "industry-el-table": Table,
    "el-pagination": Pagination,
    "resource-add": Add,
    Matter,
  },
  data() {
    return {
      tab: 0,
      tableData: [],
      page: 1,
      pageSize: 10,
      dialogVisible: false,
      url: "",
      isLoading: false,
      total: 0,
      menus: [
        {
          title: "企业库",
          defaultIcon: "resource/resource_tab_1.png",
          ActiveDefaultIcon: "resource/resource_tab_1_active.png",
          tableTh: [
            {
              title: "企业名称",
              type: "entName",
              className: "active-color clicked",
              width: "300",
            },
            {
              title: "录入时间",
              type: "createTime",
              className: "",
              headerAlgin: "center",
              width: "160",
            },
            {
              title: "录入人",
              type: "creator",
              className: "",
            },
            {
              title: "当前状态",
              type: "status",
              className: "",
            },
          ],
        },
        {
          title: "机构库",
          defaultIcon: "resource/resource_tab_2.png",
          ActiveDefaultIcon: "resource/resource_tab_2_active.png",
          isClick: false, //列表是否可点击
          tableTh: [
            {
              title: "机构名称",
              type: "orgName",
              className: "active-color",
            },
            {
              title: "录入时间",
              type: "createTime",
              className: "",
              headerAlgin: "center",
              width: "160",
            },
            {
              title: "录入人",
              type: "creator",
              className: "",
            },
            {
              title: "线索推荐状态",
              type: "status",
              className: "",
            },
          ],
        },
        {
          title: "顾问库",
          defaultIcon: "resource/resource_tab_3.png",
          ActiveDefaultIcon: "resource/resource_tab_3_active.png",
          isClick: false,
          tableTh: [
            {
              title: "顾问姓名",
              type: "name",
              className: "active-color",
            },
            {
              title: "录入时间",
              type: "createTime",
              className: "",
              headerAlgin: "center",
              width: "160",
            },
            {
              title: "录入人",
              type: "creator",
              className: "",
            },
            {
              title: "联系方式",
              type: "tel",
              className: "",
            },
            {
              title: "线索推荐状态",
              type: "status",
              className: "",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.tab = this.$route.query.tab
      ? Number(this.$route.query.tab)
      : Number(this.tab);
    this.getData();
  },
  methods: {
    addGroup() {
      this.url = URL.API_CRM_IMPORT + "/" + (this.tab - "" + 1);
      this.$refs.showDiogle.showDialogVisible();
    },
    changePage(val) {
      this.page = val;
      this.getData();
    },
    changeTab(index) {
      this.tab = Number(index);
      this.tableData = [];
      this.page = 1;
      this.$router.replace({
        path: this.$route.path,
        query: { tab: this.tab },
      });
      this.getData();
    },
    getData() {
      let tab = Number(this.tab) + 1;
      const url = `${tab}/${this.page}/${this.pageSize}`;
      crmResList(url).then((res) => {
        if (res.status == 200) {
          this.tableData = res.data;
          this.tableData.forEach((element) => {
            if (element.itemId) {
              var item = element.itemId.split(",");
              element.itemId = item[0];
              if (item.length > 1) {
                element.children = [];
                for (let i = 1; i < item.length; i++) {
                  element.children.push({
                    itemId: item[i],
                    type: "matter",
                  });
                }
              }
            }
          });
        }
      });
      crmListPageCount(tab).then((res) => {
        this.total = res.data;
      });
    },
    // 完成上传
    finishAdd() {
      this.getData();
    },
    showMatter(index, row) {
      const id = row.itemId;
      this.$refs.matter.open(id);
    },
    detailEvent(row){
      this.$router.push("company/" + row.id);
    }
  },
};
</script>

<style lang="scss" scoped>
.resource-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 26px 26px 0;
  background: #fbfbff;
  .el-container {
    height: 100%;
    background: #fff;
  }
}
.menu-box {
  height: 95%;
  padding-top: 15px;
}
.main-box {
  padding-top: 25px;
}
.table-box {
  margin: 40px 0 10px;
  box-sizing: border-box;
}
.el-button {
  padding: 8px 16px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 1px;
  color: #374567;
  margin-left: 0;
  margin-right: 12px;
}
</style>