<template>
  <div>
    <a :href="staticUrl + downloadUrl" class="active-color download-txt">
      <img src="@/assets/images/resource/downloadIcon.png" alt="" /><span
        >下载导入空的模版表格</span
      >
    </a>
    <el-upload
      class="upload-demo"
      :action="url"
      drag
      :on-success="handleSuccess"
      ref="upload"
    >
      <img class="inbox" src="@/assets/images/resource/inbox.png" alt="" />
      <div class="el-upload__text">
        <div><em>点击</em>或将文件拖拽到这里上传</div>
        <div class="el-upload__tip">支持扩展名：xlsx.</div>
      </div>
    </el-upload>
    <!-- <div>
      <div class="el-upload__tip">
        <img src="@/assets/images/paperclip.png" alt="" /> 企业名单.xlsx
      </div>
    </div> -->
  </div>
</template>

<script>
import { URL } from "@/util/request.js";
export default {
  props: {
    url: {
      type: String,
    },
    downloadUrl: {
      type: String,
    },
  },
  watch: {
    $route() {
      if (this.fileList) {
        this.$refs.upload.clearFiles();
      }
    },
  },
  methods: {
    handleSuccess(response) {
      this.fileList = response;
      this.$emit("handleSuccess", response);
    },
  },
  data() {
    return {
      staticUrl: URL.static,
    };
  },
};
</script>

<style lang="scss" scoped>
.upload-demo {
  .inbox {
    width: 48px;
    margin-bottom: 20px;
  }
}
::v-deep .el-upload-dragger {
  background: rgba(0, 0, 0, 0.02);
  padding: 37px 136px;
  width: auto;
  height: auto;
}
.el-upload__tip {
  color: rgba(55, 69, 103, 0.45);
  img {
    width: 13px;
    vertical-align: middle;
  }
}
.download-txt {
  margin-bottom: 24px;
  display: block;
  img {
    width: 13px;
    vertical-align: middle;
  }
}
</style>