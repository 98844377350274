<template>
  <div class="el-upload-box">
    <el-upload
      ref="upload"
      :downloadUrl="downloadUrl"
      @handleSuccess="handleSuccess"
      :url="url"
    ></el-upload>
  </div>
</template>

<script>
import Upload from "@/components/upload";

export default {
  props: {
    url: {
      type: String,
    },
    downloadUrl: {
      type: String,
    },
    step: {
      type: Number,
    },
  },
  data() {
    return {
      sucState: true,
    };
  },
  components: {
    "el-upload": Upload,
  },
  watch: {},
  methods: {
    handleSuccess(response) {
      this.$emit("handleSuccess", response);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>